







































































import {Component, Prop, Vue} from "vue-property-decorator";
import {MachineImageType, Maintenance} from "@/models/maintenance";
import {MachineImage} from "@/models/machineImage";
import ImageRepository from "@/api/ImageRepository";
import {RepositoryFactory} from "@/api/RepositoryFactory";

const imageRepository: ImageRepository = RepositoryFactory.get('image');

@Component({
  components: {
    ImageCardComponent: () => import('./../ImageCard.component.vue'),
  }
})
export default class MaintenanceImageGalleryComponent extends Vue {

  @Prop({default: () => new Maintenance({})})
  public maintenance!: Maintenance;

  public dragover: boolean = false;
  public uploadedFiles: Array<any> = []

  public removedImage(image: MachineImage) {
    this.maintenance.maintenanceImages = this.maintenance.maintenanceImages.filter(img => img.id !== image.id);
  }

  removeFile(filename: string) {
    // Find the index of the
    const index = this.uploadedFiles.findIndex(
        file => file.name === filename
    );
    // If file is in uploaded files remove it
    if (index > -1) this.uploadedFiles.splice(index, 1);
  }

  onDrop(dropEvent: any) {
    this.dragover = false;
    // If there are already uploaded files remove them
    if (this.uploadedFiles.length > 0)
      this.uploadedFiles = [];
    else {
      const files = Array.from(dropEvent.dataTransfer.files);
      files.forEach((element: any) => {
            element.url = URL.createObjectURL(element);
            this.uploadedFiles.push(element)
          }
      );
    }
  }

  public async submit() {
    try {
      const promises = this.uploadedFiles.map(file =>
          imageRepository.uploadImage(file, MachineImageType.imageMaintenance, { machine: null, maintenance: this.maintenance }));
      const responses = await Promise.all(promises);
      responses.forEach(imageResponse => {
        this.maintenance.maintenanceImages.push(new MachineImage(imageResponse.data, MachineImageType.imageMaintenance));
      });
      this.uploadedFiles = [];
    } catch (e) {
      console.log('error');
    }

  }
}
